<template>
  <div>
<!--     <ais-instant-search :search-client="searchClient" index-name="admin_search">
      <ais-search-box />
      <ais-hits>
        <div slot="item" slot-scope="{ item }">
          <h2>{{ item.name }}</h2>
        </div>
      </ais-hits>
    </ais-instant-search> -->
<!--     <v-text-field
      placeholder="Search"
      background-color="rgba(255, 255, 255, 0.4)"
      prepend-inner-icon="mdi-magnify"
      class="mb-0"
      rounded
      clearable
      hide-details
      outlined
      dense
    /> -->
  </div>
</template>
<script type="text/javascript">
import algoliasearch from 'algoliasearch/lite'

export default {
  name: 'AppSearch',

  data() {
    return {
      searchClient: algoliasearch(
        '7RNO75LEZ3',
        'b8498f85de6f31014063fb47b4ac3cc8'
      )
    }
  }
}
</script>
<style>
body {
  font-family: sans-serif;
  padding: 1em;
}
</style>
