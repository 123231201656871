<template>
  <v-app>    
    <AppNavigation :drawer.sync="drawer" />

    <AppBar :drawer.sync="drawer" />

    <v-main>
      <router-view/>
    </v-main>

    <div class="app_version">
      <p>havebike B2B v0.16</p>
    </div>
  </v-app>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import AppNavigation from '@/components/layout/AppNavigation'

export default {
  name: 'App',

  components: {
    AppBar,
    AppNavigation,
  },

  data: () => ({
    drawer: false
  })
}
</script>
<style type="text/css">
  .bg-primary {
    background: #00a09b;
  }
  .bg-gray {
    background: #545454;
  }
  .text-white {
    color: white;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .w-full {
    width: 100%;
  }
  .align-items-center {
    align-items: center;
  }
  .app_version {
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: grey;
    padding: 0px 0px 0px 10px;
    display: block;
  }
</style>
