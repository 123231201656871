<template>
  <v-app-bar
    v-if="$route.name !== 'login'"
    color="primary"
    app
    dark
    dense
  >
    <div
      style="height: 100%;"
      class="w-full d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <v-app-bar-nav-icon @click.stop="$emit('update:drawer', !drawer)" />
        <router-link :to="{ name: 'dashboard' }">
          <img src="@/assets/hb-logo-white-cropped.png" class="logo">
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <AppSearch />
      </div>
      <div class="d-flex align-items-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              width="300px"
              outlined
              dark
              v-on="on"
            >
              {{ $auth.actingAs.name }}
              <v-icon right small>mdi-menu-down-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(delegate, index) in $auth.userDelegates"
              :key="index"
              :class="{ 'bg-primary': delegate.id === $auth.actingAs.id }"
              @click="$store.commit('user/setActingAs', delegate)"
            >
              <v-list-item-title>
                <v-icon left small>mdi-account-circle</v-icon>
                {{ delegate.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>
<script type="text/javascript">
import AppSearch from '@/components/layout/AppSearch'

export default {
  name: 'AppBar',

  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },

  components: {
    AppSearch
  }
}
</script>
<style type="text/css">
  .logo {
    width: 110px;
    height: auto;
  }
</style>
