import store from '@/store/'
import axios from 'axios'

export default {
  auth (to, from, next) {
    if (from.name !== 'login') {
      store.getters['auth/isAuthenticated']
        .then(isLoggedIn => {
          if (isLoggedIn) {
            next()
          } else {
            next({ name: 'login' })
          }
        })
    } else {
      next()
    }
  },

  guest (to, from, next) {
    axios.get(`${process.env.VUE_APP_API_BASE_URL}/home`)
      .then(() => {
        next({ name: 'dashboard' })
      })
      .catch(() => {
        next()
      })
  }
}
