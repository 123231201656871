import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import Api from './api/'
import axios from 'axios'
import Echo from "laravel-echo"
import websockets from './websockets.js'
import moment from 'moment'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import InstantSearch from 'vue-instantsearch'

Bugsnag.start({
  apiKey: 'b0e945cd761cd81d9c5e151d6ced63b4',
  plugins: [new BugsnagPluginVue()]
})

axios.defaults.withCredentials = true
Vue.config.productionTip = false

Vue.use(Api, { baseUrl: process.env.VUE_APP_API_BASE_URL })
Vue.use(InstantSearch)

if (websockets.client) {
  window.Echo = new Echo({
      broadcaster: 'pusher',
      client: websockets.client
  })
}

Vue.mixin({
  computed: {
    $auth () {
      return {
        showAuthModal: this.$store.getters['auth/showAuthModal'],
        isAuthenticated: this.$store.getters['auth/isAuthenticated'],
        user: this.$store.getters['user/user'],
        account: this.$store.getters['user/user'].account || {},
        actingAs: this.$store.getters['user/actingAs'],
        userDelegates: this.$store.getters['user/userDelegates'],
        userCan: (permission) => {
          return this.$store.getters['user/hasPermission'](permission)
        },
        reAuthUsingModal: () => {
          this.$store.commit('auth/showAuthModal')
        },
        updateUser: (obj) => {
          return this.$store.dispatch('user/updateUser', obj)
        },
        getUser: () => {
          return this.$store.dispatch('user/getUser')
        },
        login: (obj) => {
          return this.$store.dispatch('auth/login', obj)
        },
        logout: () => {
          this.$store.dispatch('auth/logout')
        }
      }
    }
  },

  methods: {
    __ (string) {
      return string
    },

    $currency (value) {
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value)
    },

    $moment (date, utc = false) {
      if (utc) {
        return moment.utc(date)
      }
      return moment(date)
    },

    $momentDate (date) {
      return this.$moment(date).format('Do MMM YYYY')
    },

    $momentDateTime (date, utc = false) {
      return this.$moment(date, utc).format('YYYY-MM-DD HH:mm:SS')
    },

    $momentDateTimeHuman (date, utc = false) {
      return this.$moment(date, utc).format('Do MMM YYYY - HH:mm:SS')
    }
  }
})

Vue.directive('user-can', {
  inserted(el, binding, vnode) {
    if (!vnode.context.$auth.userCan(binding.arg || binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
})
Vue.directive('user-cant', {
  inserted(el, binding, vnode) {
    if (vnode.context.$auth.userCan(binding.arg || binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  mounted () {
    this.$auth.getUser()
  },
  render: h => h(App)
}).$mount("#app");
