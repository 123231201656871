import axios from 'axios'
import router from '@/router/'

// initial state
const state = {
  authModal: false
}

// getters
const getters = {
  showAuthModal: (state) => {
    return state.authModal
  },

  isAuthenticated: () => {
    return new Promise((resolve) => {
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/home`)
        .then(() => {
          resolve(true)
        })
        .catch((response) => {
          if (!response.response) {
            resolve(false)
          }
          resolve(false)
        })
        .then(() => {
          resolve(false)
        })
    })
  }
}

// actions
const actions = {
  login ({ commit, dispatch }, authDetails) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/login`, {
        email: authDetails.username,
        password: authDetails.password,
        remember: authDetails.remember
      })
        .then(() => {
          //
        })
        .catch(error => {
          reject(error)
        })
        .then(function () {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/home`)
            .then(() => {
              dispatch('user/getUser', null, { root: true })
              commit('handleSuccessfulLogin')
              resolve()
            })
        })
    })
  },

  logout ({ commit, dispatch }) {
    axios.post(`${process.env.VUE_APP_API_BASE_URL}/logout`)
      .then(() => {
        //
      })
      .catch(() => {
        //
      })
      .then(() => {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/home`)
          .catch(() => {
            dispatch('user/clearUser', null, { root: true })
            commit('handleSuccessfulLogout')
          })
      })
  }
}

// mutations
const mutations = {
  handleSuccessfulLogin () {
    // router.replace({ name: 'dashboard' })
    location.reload()
  },

  handleSuccessfulLogout () {
    router.replace({ name: 'login' })
  },

  showAuthModal (state) {
    state.authModal = true
  },

  hideAuthModal (state) {
    state.authModal = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
