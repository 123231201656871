import axios from 'axios'

// initial state
const state = {
  user: {
    delegates: []
  },
  actingAs: {}
}

// getters
const getters = {
  user: (state) => {
    return state.user
  },
  actingAs: (state) => {
    return state.actingAs
  },
  userDelegates: (state) => {
    return [...[state.user], ...state.user.delegates]
  },
  userPermissions: (state) => {
    var permissions = []
    if (state.user.roles) {
      state.user.roles.forEach(role => {
        role.permissions.forEach(permission => {
          permissions.push(permission.name)
        })
      })
    }
    return permissions
  },
  hasPermission: (state, getters) => (permission) => {
    var hasRole = false
    if (getters.userPermissions) {
      getters.userPermissions.forEach(userPermission => {
        if (userPermission === permission) {
          hasRole = true
        }
      })
    }
    return hasRole
  }
}

// actions
const actions = {
  getUser ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
        params: {
          with: ['roles.permissions', 'delegates', 'account']
        }
      })
        .then(response => {
          commit('setUser', response.data.data)
          if (!getters.actingAs.id) {
            commit('setActingAs', response.data.data)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateUser ({ commit }, obj) {
    return new Promise((resolve) => {
      commit('setUser', obj)
      resolve()
    })
  },

  clearUser ({ commit }) {
    commit('clearUser')
    commit('clearActingAs')
  }
}

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user
  },

  setActingAs (state, user) {
    state.actingAs = user
  },

  clearActingAs (state) {
    state.actingAs = {}
  },

  clearUser (state) {
    state.user = {
      delegates: []
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
