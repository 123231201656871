<template>
  <v-navigation-drawer
    v-if="$route.name !== 'login'"
    v-model="innerDrawer"
    app
  >
    <v-list
      nav
      dense
    >
      <v-list-item
        :to="{ name: 'dashboard' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_ACCOUNT_ASSETS
        :to="{ name: 'assets' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-clipboard-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Assets</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_ACCOUNT_USERS
        :to="{ name: 'users' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        prepend-icon="mdi-account-circle"
      >
        <template v-slot:activator>
          <v-list-item-title>My Account</v-list-item-title>
        </template>

        <!-- THEME -->
        <v-list-item
          link
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-list-item-action>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Theme</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- LOGOUT -->
        <v-list-item link @click="$emit('update:drawer', false); $auth.logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ __('Logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </v-list>
  </v-navigation-drawer>
</template>
<script type="text/javascript">
export default {
  name: 'AppNavigation',

  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    innerDrawer: {
      get () {
        return this.drawer
      },
      set (localValue) {
        this.$emit('update:drawer', localValue)
      }
    }
  }
}
</script>
