import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/router/middleware'

const Dashboard = () => import(/* webpackChunkName: "dashboard-page" */ '@/views/Dashboard')
const LoginPage = () => import(/* webpackChunkName: "login-page" */ '@/views/LoginPage')
const NewBooking = () => import(/* webpackChunkName: "new-booking-page" */ '@/views/NewBooking')
const NewAsset = () => import(/* webpackChunkName: "new-asset-page" */ '@/views/NewAsset')
const NewUser = () => import(/* webpackChunkName: "new-user-page" */ '@/views/NewUser')
const NewReport = () => import(/* webpackChunkName: "new-report-page" */ '@/views/NewReport')
const ShowAsset = () => import(/* webpackChunkName: "show-asset-page" */ '@/views/ShowAsset')
const Assets = () => import(/* webpackChunkName: "assets-page" */ '@/views/Assets')
const Reports = () => import(/* webpackChunkName: "reports-page" */ '@/views/Reports')
const Users = () => import(/* webpackChunkName: "users-page" */ '@/views/Users')
const NotFoundPage = () => import(/* webpackChunkName: "not-found-page" */ '@/views/errors/NotFoundPage')
const UnauthorisedPage = () => import(/* webpackChunkName: "unauthorised-page" */ '@/views/errors/UnauthorisedPage')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: middleware.auth
  },
  {
    path: '/bookings/new',
    name: 'bookings.new',
    component: NewBooking,
    beforeEnter: middleware.auth
  },
  {
    path: '/assets',
    name: 'assets',
    component: Assets,
    beforeEnter: middleware.auth
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    beforeEnter: middleware.auth
  },
  {
    path: '/assets/new',
    name: 'assets.new',
    component: NewAsset,
    beforeEnter: middleware.auth
  },
  {
    path: '/assets/:assetId',
    name: 'assets.show',
    component: ShowAsset,
    beforeEnter: middleware.auth,
    props: true
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    beforeEnter: middleware.auth
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: NewUser,
    beforeEnter: middleware.auth
  },
  {
    path: '/reports/new',
    name: 'reports.new',
    component: NewReport,
    beforeEnter: middleware.auth
  },
  {
    path: '/auth/login',
    name: 'login',
    component: LoginPage,
    beforeEnter: middleware.guest
  },
  {
    path: '/errors/unauthorised',
    name: 'error-403',
    component: UnauthorisedPage
  },
  {
    path: '*',
    name: 'error-404',
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
