const Pusher = require('pusher-js')
var PusherClient = null

function getToken() {
  var cookieName = 'XSRF-TOKEN' + "="
  var cookies = decodeURIComponent(document.cookie).split(';')
  for (var i = 0; i <cookies.length; i++) {
    var c = cookies[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(cookieName) == 0) {
      return c.substring(cookieName.length, c.length)
    }
  }
}

Pusher.Runtime.createXHR = function () {
  var xhr  = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}

if (process.env.VUE_APP_WEBSOCKET_KEY) {
  PusherClient = new Pusher(process.env.VUE_APP_WEBSOCKET_KEY, {
    wsHost: process.env.VUE_APP_API_BASE_URL.split('://')[1],
    authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/broadcasting/auth`,
    wsPort: process.env.NODE_ENV === 'production' ? 2053 : 6001,
    wssPort: process.env.NODE_ENV === 'production' ? 2053 : 6001,
    disableStats: false,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        'X-XSRF-Token': getToken()
      }
    }
  })
}

export default {
  client: PusherClient
}